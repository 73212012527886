import React from 'react'
import { Router } from '@reach/router'
import MatchView from "../components/app/features/request/match_view"
import hasParams from "../components/has_params"
import Layout from "../components/layout"

const View = (props) => {
  return (
    <Layout>
      <Router>
        <MatchView
          {...props}
          path="/view/:requestId"/>
      </Router>
    </Layout>
  );
}

export default hasParams(View);
